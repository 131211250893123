import tippy from 'tippy.js';

const DEFAULT_PROPS = {
  theme: 'clearstream',
  ignoreAttributes: true,
};

export default {
  mounted(el, { value: props }) {
    tippy(el, {
      ...DEFAULT_PROPS,
      ...props,
    });
  },
  updated(el, { value: props }) {
    el._tippy.setProps({
      ...DEFAULT_PROPS,
      ...props,
    });
  },
  unmounted(el) {
    el._tippy.destroy();
  },
};
