// Context: https://www.stefanjudis.com/snippets/turn-off-password-managers/

export default {
  mounted(el, { value = true }) {
    if (!value) {
      return;
    }

    el.setAttribute('autocomplete', 'off');
    // 1Password
    el.setAttribute('data-1p-ignore', '');
    // Bitwarden
    el.setAttribute('data-bwignore', '');
    // Lastpass
    el.setAttribute('data-lpignore', 'true');
    // Dashlane
    el.setAttribute('data-form-type', 'other');
  },
};
