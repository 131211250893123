export default (
  phoneNumber: string,
  { keepCountryCode = false } = {},
): string => {
  if (!phoneNumber) {
    return phoneNumber;
  }

  // The only kind of phone number we want to format is E164 US number (+1).
  const usPhoneNumberMatch = phoneNumber.match(/^\+(1)(\d{3})(\d{3})(\d{4})$/);

  if (usPhoneNumberMatch) {
    if (keepCountryCode) {
      return `+1 (${usPhoneNumberMatch[2]}) ${usPhoneNumberMatch[3]}-${usPhoneNumberMatch[4]}`;
    }

    return `(${usPhoneNumberMatch[2]}) ${usPhoneNumberMatch[3]}-${usPhoneNumberMatch[4]}`;
  }

  // Otherwise, we'll just return the input string without any modifications.
  return phoneNumber;
};
