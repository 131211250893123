import { AxiosResponse } from 'axios';

let previousVersion: string | null = null;
let outdated = false;

function handleIncomingAssetsVersion(version: string | null | undefined): void {
  if (!version) {
    return;
  }

  if (previousVersion && previousVersion !== version) {
    outdated = true;
  }

  previousVersion = version;
}

function handleIncomingAssetsVersionFromAxiosResponse(
  response: AxiosResponse | null | undefined,
): void {
  if (response?.headers) {
    handleIncomingAssetsVersion(response.headers['x-assets-version']);
  }
}

function shouldAssetsBeRefreshed(): boolean {
  return outdated;
}

export {
  handleIncomingAssetsVersionFromAxiosResponse,
  shouldAssetsBeRefreshed,
};
