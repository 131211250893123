export default (error, fallback) => {
  const responseDataErrorMessage = _.get(error, 'response.data.error.message');

  if (responseDataErrorMessage) {
    return responseDataErrorMessage;
  }

  if (isCloudflareError(error)) {
    const cloudflareRayId = getCloudflareRayId(error);

    return cloudflareRayId
      ? `Connection to the server was interrupted. Please try again later. (Ray ID: ${cloudflareRayId})`
      : 'Connection to the server was interrupted. Please try again later.';
  }

  return fallback || 'There was an error communicating with the server.';
};

function isCloudflareError(error) {
  const data = _.get(error, 'response.data');

  return typeof data === 'string' && String(data).includes('cf-wrapper');
}

function getCloudflareRayId(error) {
  return _.get(error, 'response.headers.cf-ray');
}
