import { DateTime } from 'luxon';
import formatDateTime from '@web/utils/formatDateTime';

export default function timeago(
  dateTime: string | DateTime | null,
): string | null {
  if (dateTime === null) {
    return null;
  }

  if (typeof dateTime === 'string') {
    dateTime = DateTime.fromISO(dateTime);
  }

  if (Math.abs(dateTime.diffNow().as('days')) > 14) {
    return formatDateTime(dateTime);
  }

  return dateTime.toRelative({ locale: 'en' });
}
