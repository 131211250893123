const prefix = '__LOCKER__';

function prefixedKey(key) {
  return prefix + key;
}

function isExpired(unserializedPayload) {
  return (
    unserializedPayload.hasOwnProperty('expiry') &&
    unserializedPayload['expiry'] < new Date().getTime()
  );
}

function isLockerKey(key) {
  return key.indexOf(prefix) === 0;
}

class Locker {
  set(key, value, ttl = null) {
    const unserializedPayload = { value };

    if (ttl !== null) {
      unserializedPayload['expiry'] = new Date().getTime() + ttl;
    }

    const serializedPayload = JSON.stringify(unserializedPayload);

    localStorage.setItem(prefixedKey(key), serializedPayload);
  }

  forget(key) {
    localStorage.removeItem(prefixedKey(key));
  }

  get(key, defaultValue = null) {
    const serializedPayload = localStorage.getItem(prefixedKey(key));

    if (serializedPayload !== null) {
      const unserializedPayload = JSON.parse(serializedPayload);

      if (!isExpired(unserializedPayload)) {
        return unserializedPayload['value'];
      }

      this.forget(key);
    }

    return defaultValue;
  }

  all() {
    return Object.keys(localStorage)
      .filter((key) => isLockerKey(key))
      .reduce((acc, prefixedKey) => {
        const unprefixedKey = prefixedKey.slice(prefix.length);

        acc[unprefixedKey] = this.get(unprefixedKey);

        return acc;
      }, {});
  }

  flush() {
    Object.keys(localStorage)
      .filter((key) => isLockerKey(key))
      .forEach((prefixedKey) => {
        localStorage.removeItem(prefixedKey);
      });
  }

  flushExpired() {
    Object.keys(localStorage)
      .filter((key) => isLockerKey(key))
      .filter((prefixedKey) =>
        isExpired(JSON.parse(localStorage.getItem(prefixedKey))),
      )
      .forEach((prefixedKey) => {
        localStorage.removeItem(prefixedKey);
      });
  }
}

export default Locker;
