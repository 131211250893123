function observe(el, { value: callback, modifiers: { once } }) {
  const intersectionObserver = new IntersectionObserver((entries) => {
    if (entries.some((entry) => entry.isIntersecting)) {
      callback();

      if (once) {
        unobserve(el);
      }
    }
  });
  intersectionObserver.observe(el);
  el._intersectionObserver = intersectionObserver;
}

function unobserve(el) {
  if (el._intersectionObserver) {
    el._intersectionObserver.unobserve(el);
    delete el._intersectionObserver;
  }
}

export default {
  mounted(el, binding) {
    observe(el, binding);
  },
  updated(el, binding) {
    unobserve(el);
    observe(el, binding);
  },
  unmounted(el) {
    unobserve(el);
  },
};
