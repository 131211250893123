import { DateTime } from 'luxon';

// Table of tokens: https://moment.github.io/luxon/#/formatting?id=table-of-tokens
export default (dateTime: string | DateTime, format: string = 'M/d/yy') => {
  if (dateTime === null) {
    return null;
  }

  if (typeof dateTime === 'string') {
    dateTime = DateTime.fromISO(dateTime);
  }

  if (!DateTime.isDateTime(dateTime)) {
    throw new Error('Invalid argument provided to formatDateTime().');
  }

  return dateTime.toFormat(format, { locale: 'en' });
};
