// Inspired by: https://github.com/ndelvalle/v-click-outside/blob/1f184835f9c17d2bc6334b9dd2fb300b51183bea/src/v-click-outside.js

const HANDLERS_PROPERTY = '__CLICK_OUTSIDE_HANDLERS__';

function initialize(el, handler) {
  if (typeof handler !== 'function') {
    throw new Error('v-click-outside: Handler must be a function');
  }

  let lastMousedownInside = false;
  let lastMouseupInside = false;

  el[HANDLERS_PROPERTY] = [
    {
      event: 'click',
      handler(e) {
        const clickInside = el.contains(e.target);

        if (!clickInside && !lastMousedownInside && !lastMouseupInside) {
          handler(e);
        }
      },
    },
    {
      event: 'mousedown',
      handler: (e) => (lastMousedownInside = el.contains(e.target)),
    },
    {
      event: 'mouseup',
      handler: (e) => (lastMouseupInside = el.contains(e.target)),
    },
  ];

  setTimeout(() => {
    if (el[HANDLERS_PROPERTY]) {
      el[HANDLERS_PROPERTY].forEach(({ event, handler }) => {
        document.addEventListener(event, handler);
      });
    }
  }, 0);
}

function terminate(el) {
  (el[HANDLERS_PROPERTY] ?? []).forEach(({ event, handler }) => {
    document.removeEventListener(event, handler);
  });
  delete el[HANDLERS_PROPERTY];
}

export default {
  beforeMount(el, { value }) {
    initialize(el, value);
  },
  updated(el, { value, oldValue }) {
    if (value !== oldValue) {
      // Usually it happens when you define handler inline (`v-click-outside="visible = false"`)
      // rather than referencing the component's method (`v-click-outside="hide"`).
      throw new Error('v-click-outside: Updating value is not supported');
    }
  },
  unmounted(el) {
    terminate(el);
  },
};
