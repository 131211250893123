export default {
  mounted(el, { value: callback }) {
    el._resizeObserver = new ResizeObserver(([{ contentRect }]) => {
      callback(contentRect);
    });

    el._resizeObserver.observe(el);
  },
  unmounted(el) {
    if (el._resizeObserver) {
      el._resizeObserver.disconnect();
    }
  },
};
