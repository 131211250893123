class ThirdPartyServices {
  static init() {
    const { global, user } = window.Clearstream;

    if (user) {
      window.Intercom &&
        window.Intercom('boot', {
          // https://developers.intercom.com/installing-intercom/docs/javascript-api-attributes-objects
          app_id: global.keys.intercom,
          user_id: user.id,
          user_hash: user.intercomHash,
          session_duration:
            window.Clearstream.global.sessionLifetime * 60 * 1000,
        });

      window.smartlook &&
        window.smartlook('identify', user.id, { email: user.email });
    } else {
      window.Intercom &&
        window.Intercom('boot', {
          app_id: global.keys.intercom,
        });
    }
  }

  static reportRouteChange(fullPath) {
    // Every time we change the URL, we need to let Intercom know.
    // https://www.intercom.com/help/en/articles/170-integrate-intercom-in-a-single-page-app
    window.Intercom &&
      window.Intercom('update', {
        last_request_at: Math.ceil(new Date().getTime() / 1000),
      });

    // https://bingadsuet.azurewebsites.net/SPA_Index.html
    window.uetq &&
      window.uetq.push('event', 'page_view', { page_path: fullPath });

    // https://developers.google.com/analytics/devguides/collection/gtagjs/single-page-applications#measure_virtual_pageviews
    window.gtag && window.gtag('set', 'page_path', fullPath);
    window.gtag && window.gtag('event', 'page_view');
  }

  static reportLogout() {
    window.smartlook && window.smartlook('anonymize');

    // We must clear the Intercom session on logout.
    // https://www.intercom.com/help/en/articles/16845-how-do-i-end-a-session
    window.Intercom && window.Intercom('shutdown');
  }

  static reportLead({ email, name }) {
    // Cometly
    window.comet &&
      window.comet('lead_generated', {
        email,
        full_name: name,
        user_agent: window.navigator.userAgent,
      });

    // AdRoll
    try {
      window.__adroll.track('pageView', { segment_name: 'bf32aeef' });
    } catch (err) {
      //
    }

    // Twitter
    window.twq &&
      window.twq('event', 'tw-oamkn-onebo', {
        email_address: email,
        // phone_number: null,
      });
  }

  static reportSignup({ accountId, conversionValue, userId, userEmail }) {
    // Google Analytics & Google Adwords
    // We need to set these up based on the data returned
    // after the sign up, since they weren't available
    // on page load.
    window.gtag &&
      window.gtag('set', {
        user_id: userId,
        dimension2: userId,
        dimension3: accountId,
      });

    // Google Analytics
    window.gtag &&
      window.gtag('event', 'purchase', {
        transaction_id: signupTransactionId(accountId),
        value: conversionValue,
        currency: 'USD',
      });

    // Google Ads
    window.gtag &&
      window.Clearstream.global.keys.google.ads.conversionSendTo &&
      window.gtag('event', 'conversion', {
        send_to: window.Clearstream.global.keys.google.ads.conversionSendTo,
        value: conversionValue,
        currency: 'USD',
        transaction_id: signupTransactionId(accountId),
      });

    // SmartLook
    window.smartlook &&
      window.smartlook('identify', userId, { email: userEmail });
    window.smartlook &&
      window.smartlook(
        'track',
        conversionValue > 0 ? 'cs-account-signup' : 'cs-account-signup-free',
        {
          value: conversionValue,
          transactionId: signupTransactionId(accountId),
        },
      );

    // Bing
    window.uetq &&
      window.uetq.push('event', 'account_signup', {
        event_label: `account_${accountId}`,
        revenue_value: conversionValue,
        currency: 'USD',
      });

    // Bing (new)
    window.uetq &&
      window.uetq.push('event', conversionValue > 0 ? 'purchase' : 'trial', {
        revenue_value: conversionValue,
        currency: 'USD',
      });

    if (conversionValue > 0) {
      // AdRoll
      try {
        window.__adroll.track('pageView', { segment_name: '1b0e652d' });
      } catch (err) {
        //
      }
      window.adroll &&
        window.adroll.track('purchase', {
          order_id: accountId,
          currency: 'USD',
          conversion_value: conversionValue,
        });
    } else {
      // AdRoll
      try {
        window.__adroll.track('pageView', { segment_name: '250d2dd3' });
      } catch (err) {
        //
      }
    }

    if (conversionValue > 0) {
      // Twitter
      window.twq &&
        window.twq('event', 'tw-oamkn-oneas', {
          value: conversionValue,
          currency: 'USD',
          email_address: userEmail,
          // phone_number: null,
        });
    } else {
      // Twitter
      window.twq &&
        window.twq('event', 'tw-oamkn-onebp', {
          email_address: userEmail,
          // phone_number: null,
        });
    }

    // LinkedIn
    window.lintrk &&
      window.Clearstream.global.keys.linkedin.conversionId &&
      window.lintrk('track', {
        conversion_id: window.Clearstream.global.keys.linkedin.conversionId,
      });

    // Facebook
    window.fbq &&
      window.fbq('track', 'Subscribe', {
        value: conversionValue,
        currency: 'USD',
      });
  }

  static reportUpgradeFromFreePlan({ accountId, conversionValue, userEmail }) {
    // Google Analytics
    window.gtag &&
      window.gtag('event', 'purchase', {
        transaction_id: upgradeTransactionId(accountId),
        value: conversionValue,
        currency: 'USD',
      });

    // Google Ads
    window.gtag &&
      window.Clearstream.global.keys.google.ads.conversionSendTo &&
      window.gtag('event', 'conversion', {
        send_to: window.Clearstream.global.keys.google.ads.conversionSendTo,
        value: conversionValue,
        currency: 'USD',
        transaction_id: upgradeTransactionId(accountId),
      });

    // SmartLook
    window.smartlook &&
      window.smartlook('track', 'cs-account-upgrade-from-free-plan', {
        value: conversionValue,
        transactionId: upgradeTransactionId(accountId),
      });

    // Bing
    window.uetq &&
      window.uetq.push('event', 'account_upgrade', {
        event_label: `account_${accountId}`,
        revenue_value: conversionValue,
        currency: 'USD',
      });

    // Bing (new)
    window.uetq &&
      window.uetq.push('event', 'purchase', {
        revenue_value: conversionValue,
        currency: 'USD',
      });

    // AdRoll
    try {
      window.__adroll.track('pageView', { segment_name: '1b0e652d' });
    } catch (err) {
      //
    }
    window.adroll &&
      window.adroll.track('purchase', {
        order_id: accountId,
        currency: 'USD',
        conversion_value: conversionValue,
      });

    // Twitter
    window.twq &&
      window.twq('event', 'tw-oamkn-oneas', {
        value: conversionValue,
        currency: 'USD',
        email_address: userEmail,
        // phone_number: null,
      });

    // Facebook
    window.fbq &&
      window.fbq('track', 'Subscribe', {
        value: conversionValue,
        currency: 'USD',
      });
  }
}

function upgradeTransactionId(accountId) {
  return `acct_upgrade_${accountId}`;
}

function signupTransactionId(accountId) {
  return `acct_signup_${accountId}`;
}

export default ThirdPartyServices;
