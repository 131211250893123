import autosize from 'autosize';

export default {
  mounted(el) {
    autosize(el);
    // This helps ensure that the initial height of the textarea will
    // be correct when the page is first loaded.
    // http://www.jacklmoore.com/autosize/#faq-hidden
    // https://github.com/vuejs/vue/issues/9200#issuecomment-468512304
    requestAnimationFrame(() => {
      autosize.update(el);
    });
  },
  updated(el) {
    autosize.update(el);
  },
  unmounted(el) {
    autosize.destroy(el);
  },
};
